import { Component, OnInit } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { getDefaultAvatar } from '../../../helpers/assets.helper';
import { UserRole } from '../../../models/collections/user.model';
import { UsersService } from '../../../services/collections/users.service';
import { AlertService } from '../../../services/alert.service';
import { I18nService } from '../../../services/i18n.service';
import { NavigationService } from '../../../services/navigation.service';

import { Post, PostStatus } from '../../../models/collections/post.model';
import { PostsService } from '../../../services/collections/posts.service';

@Component({
  selector: 'fa-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {

  firstName: string;
  lastName: string;
  places:any = []
  email: string;
  password: string;
  birthDate: string;
  role: UserRole;
  stripe:any = null
  allRoles: object|any = {};
  bio: string;
  post = "none";
  private avatar: File;
  avatarSrc: string|ArrayBuffer;
  choiceRink: any;
  allPosts: Observable<Post[]>;
  
  isIndeterminate:boolean;
  masterCheck:boolean;
  checkBoxList:any;

  constructor(
    private users: UsersService,
    private posts: PostsService,
    private alert: AlertService,
    private i18n: I18nService,
    private navigation: NavigationService
  ) { 
    this.checkBoxList = [
      {
        value:"Administrateur",
        isChecked:false
      },{
        value:"Employé",
        isChecked:false
      },{
        value:"Employé de caisse",
        isChecked:false
      },{
        value:"Client partenaire",
        isChecked:false
      },{
        value:"Chef/Régisseur",
        isChecked:false
      }
    ];
  }

  ngOnInit() {
    this.allRoles = this.users.getAllRoles();
    this.role = UserRole.Guest;
    this.avatar = null;
    this.avatarSrc = getDefaultAvatar();
    this.bio = null;
    this.allPosts = this.posts.getAll();
  }

  onAvatarChange(event: Event) {
    this.avatar = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.avatarSrc = reader.result;
    };
    reader.readAsDataURL(this.avatar);
  }


  checkAllPlaces() {
    this.places = []
    this.allPosts.subscribe(result => {
      result.forEach(result => {
        this.places.push(result.id);
      })
    })
  }


  changeRole(value:string) {

      if(value === "admin" ) {

      var $this = document.querySelectorAll('.custom-control-input');
      $this.forEach(element => {
        console.log(element)
        element.setAttribute('checked', 'true');
      });
      this.checkAllPlaces()
    } else {
      this.places = []
      var $this = document.querySelectorAll('.custom-control-input');
      $this.forEach(element => {
        console.log(element)
        element.removeAttribute('checked');
      });
    }
    

  }


  checkMaster() {
    setTimeout(()=>{
      this.checkBoxList.forEach(obj => {
        obj.isChecked = this.masterCheck;
      });
    });
  }

  checkEvent() {
    const totalItems = this.checkBoxList.length;
    let checked = 0;
    this.checkBoxList.map(obj => {
      if (obj.isChecked) checked++;
    });
    if (checked > 0 && checked < totalItems) {
      //If even one item is checked but not all
      this.isIndeterminate = true;
      this.masterCheck = false;
    } else if (checked == totalItems) {
      //If all are checked
      this.masterCheck = true;
      this.isIndeterminate = false;
    } else {
      //If none is checked
      this.isIndeterminate = false;
      this.masterCheck = false;
    }
  }

  
  onCategoryCheck(category: Post, event: Event|any) {
    if (event.target.checked) {
      this.places.push(category);
      console.log('nouvelle liste', this.places)

    } else {
      //const index = this.places.indexOf(category.id);
      const index = this.places.find(element => element == category.id);
      if (index !== -1) {
        this.places.splice(index, 1);
      }
      console.log('nouvelle liste', this.places)
    }
  }

  addUser(event: Event, form: HTMLFormElement) {
    form.isSubmitted = true;
    if (form.checkValidity()) {
      const target = event.target as any;
      const startLoading = () => {
        target.isDisabled = true;
        target.isLoading = true;
      };
      const stopLoading = () => {
        target.isDisabled = false;
        target.isLoading = false;
      };
      if(this.places.length == 0) {
        return alert('Vous devez attribuer au moins 1 établissement à l\'utilisateur pour continuer...')
      }
      startLoading();
      // Add user
      this.users.add({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        places: this.places,
        stripe:this.stripe,
        password: this.password,
        birthDate: this.birthDate ? new Date(this.birthDate).getTime() : null,
        role: this.role,
        bio: this.bio,
        avatar: this.avatar,
        rink: this.post
      }).then(() => {
        this.alert.success(this.i18n.get('UserAdded'), false, 5000, true);
        this.navigation.redirectTo('users', 'list');
      }).catch((error: Error) => {
        this.alert.error(error.message);
      }).finally(() => {
        stopLoading();
      });
    }
  }

}
