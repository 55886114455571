import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { Session, SessionStatus } from '../../models/collections/sessions.model';
import { now, guid, isFile } from '../../helpers/functions.helper';
import { StorageService } from '../storage.service';
import { map, take, mergeMap } from 'rxjs/operators';
import { of, merge, Observable } from 'rxjs';
import { getEmptyImage, getLoadingImage } from '../../helpers/assets.helper';
import { SettingsService } from '../settings.service';
import { Language } from '../../models/language.model';
import { UsersService } from './users.service';
import { QueryFn } from '@angular/fire/firestore';
import { DocumentTranslationsService } from './abstract/document-translations.service';

@Injectable()
export class SessionsService extends DocumentTranslationsService {

  private allStatus: object = {};
  private statusColors: object = {
    succeeded: 'success',
    terminated: 'primary',
    requires_payment_method: 'danger',
    creating: 'warning'
  };
  private imagesCache: object = {};

  constructor(
    protected db: DatabaseService,
    private storage: StorageService,
    private settings: SettingsService,
    private users: UsersService
  ) {
    super(db, 'postTranslations');
    Object.keys(SessionStatus).forEach((key: string) => {
      this.allStatus[SessionStatus[key]] = key;
    });
  }

  getAllStatus() {
    return this.allStatus;
  }

  getAllStatusWithColors() {
    return { labels: this.allStatus, colors: this.statusColors };
  }

  getStatus(statusKey: string) {
    return this.allStatus[statusKey];
  }

  add(data: Session, translationId?: string) {
    const post: Session = {
      orderNumber: data.orderNumber,
      status: data.status,
      created: now(), // timestamp
      lastmodified: null,
    };

    return new Promise((resolve, reject) => {
      this.db.addDocument('sessions', post).then((doc: any) => {
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }



  private uploadImage(id: string, imageFile: File) {
    return new Promise((resolve, reject) => {
      if (imageFile && isFile(imageFile)) {
        const imageName = guid() + '.' + imageFile.name.split('.').pop();
        const imagePath = `sessions/${id}/${imageName}`;
        this.storage.upload(imagePath, imageFile).then(() => {
          this.db.setDocument('sessions', id, { image: imagePath }).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          });
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  get(id: string) {
    return this.db.getDocument('orders', id).pipe(mergeMap(async (post: Session) => {
      post.id = id;
      return post;
    }));
  }



  getImageUrl(imagePath: string) {
    if (this.imagesCache[imagePath]) {
      return of(this.imagesCache[imagePath]);
    } else {
      return this.storage.get(imagePath).getDownloadURL().pipe(map((imageUrl: string) => {
        this.imagesCache[imagePath] = imageUrl;
        return imageUrl;
      }));
    }
  }

  private pipePosts(postsObservable: Observable<Session[]>) {
    return postsObservable.pipe(mergeMap(async (posts: Session[]) => {
      //const activeSupportedLanguages = this.settings.getActiveSupportedLanguages().map((lang: Language) => lang.key);
      //posts.forEach((post: Post) => { // forEach loop doesn't seems to work well with async/await
      for (let post of posts) {
        // console.log(post);
        //post.translations = await this.getTranslations(post.translationId).pipe(take(1)).toPromise();
        // console.log(post.translations);
        //const postLanguages = Object.keys(post.translations);
        //post.image = {
        //  path: post.image,
         // url: post.image ? merge(of(getLoadingImage()), this.getImageUrl(post.image as string)) : of(getEmptyImage())
        //};
        //post.author = post.createdBy ? this.users.getFullName(post.createdBy) : of(null);
        //post.isTranslatable = !activeSupportedLanguages.every((lang: string) => postLanguages.includes(lang));
      }
      //});
      return posts;
    }));
  }

  getAll() {
    return this.pipePosts(this.db.getCollection('orders'));
  }

  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('orders', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }

  getStatsResume(userPlaces) {
    return this.pipePosts(this.db.getCollection('resume', ref => ref.where('place', 'in', userPlaces)));
  }

  getStatsResumeByPlace(queryFn: QueryFn, applyPipe: boolean = false) {
    const postsObservable = this.db.getCollection('resume', queryFn);
    return applyPipe ? this.pipePosts(postsObservable) : postsObservable;
  }

  edit(id: string, data: Session) {
    const post: Session = {
      orderNumber: data.orderNumber,
      status: data.status,
      lastmodified: now(),
      cart: data.cart,
      created: data.created,
      paymentIntent: data.paymentIntent,
      place: data.place,
      total: data.total,
      uid: data.uid,
    };

    return new Promise((resolve, reject) => {
      this.db.setDocument('orders', id, post).then(() => {
        resolve();
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  private deleteImage(imagePath: string) {
    return new Promise((resolve, reject) => {
      // console.log(imagePath);
      if (imagePath) {
        this.storage.delete(imagePath).toPromise().then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  async delete(id: string, data: { imagePath: string, lang: string, translationId: string }) {
    if (data.imagePath) {
      const posts: Session[] = await this.getWhere('image', '==', data.imagePath).pipe(take(1)).toPromise();
      if (posts.length > 1) {
        data.imagePath = null; // do not delete image if used by more than 1 post
      }
    }
  }

  setStatus(id: string, status: SessionStatus) {
    return this.db.setDocument('sessions', id, { status: status });
  }

  isSlugDuplicated(slug: string, lang: string, id?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getWhereFn(ref => ref.where('slug', '==', slug).where('lang', '==', lang)).pipe(take(1)).toPromise().then((posts: Session[]) => {
        //console.log(posts, posts[0]['id']);
        resolve(posts && posts.length && (!id || (posts[0]['id'] as any) !== id));
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  countAll() {
    return this.db.getDocumentsCount('orders');
  }

  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('orders', queryFn);
  }

  countWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

}
