import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { UserRole, User } from '../../../models/collections/user.model';
import { UsersService } from '../../../services/collections/users.service';
import { I18nService } from '../../../services/i18n.service';
import { AlertService } from '../../../services/alert.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';

import { PostsService } from '../../../services/collections/posts.service';
import { Post, PostStatus } from '../../../models/collections/post.model';

@Component({
  selector: 'fa-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit, OnDestroy {

  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  birthDate: string;
  role: any;
  rink: string;
  choiceRink: any;
  places:any = []
  allRoles: object|any = {};
  bio: string;
  private avatar: File;
  avatarSrc: string|ArrayBuffer;
  private subscription: Subscription = new Subscription();
  userData: User; // used to keep track of old user data
  placeTitle = "none";
  placeAdress:{}
  post = "none";
  allPosts: Observable<Post[]>;
  stripeAccount:any = []
  resultBankLink:any
  resultBank:any = {charges_enabled:false, requirements: {disabled_reason:''}}
  loading:boolean = true

  constructor(
    private users: UsersService,
    private i18n: I18nService,
    private alert: AlertService,
    private route: ActivatedRoute,
    private posts: PostsService,
    public navigation: NavigationService
  ) { }

  ngOnInit() {
    this.allRoles = this.users.getAllRoles();
    this.allPosts = this.posts.getAll();
    this.subscription.add(
      this.route.params.subscribe((params: { id:string, account:string }) => {
        // console.log(params);
        
        if(params.account === "bankValid") {
          this.alert.success(this.i18n.get('UserBankAccountOK'), false, 7000, true);
        }
        if(params.account === "bankCancel") {
          this.alert.error(this.i18n.get('UserBankAccountCancel'), false, 7000, true);
        }

        this.loading = true
        console.log('alors ?', params.account)
        this.users.get(params.id).pipe(take(1)).toPromise().then(async (user: User) => {
          // console.log(user);
          if (user) {
            this.userData = user;
            this.id = params.id;
            this.stripeAccount = user.stripe || null
            this.firstName = user.firstName;
            this.lastName = user.lastName;
            this.email = user.email;
            this.password = user.password;
            this.birthDate = user.birthDate ? new Date(user.birthDate).toISOString().slice(0, 10) : null;
            this.role = user.role;
            this.bio = user.bio;
            this.rink = user.rink;
            this.places = user.places
            this.avatar = null;
            this.subscription.add(
              this.users.getAvatarUrl(user.avatar as string).subscribe((imageUrl: string) => {
                this.avatarSrc = imageUrl;
              })
            );
          if(this.role === "employee" || this.role === "employee_caisse" || this.role === "guest") {
            this.getPlace(this.rink)
            this.choiceRink = true;
          }
          if(this.stripeAccount !== null) {
            await this.getBankAccount()
          }
          this.loading = false
          } else {
            this.navigation.redirectTo('users', 'list');
            this.loading = false
          }
        });
      })
    );
  }

  changeRole(value:string) {
    if(value === "employee" || value === "employee_caisse" || value === "guest") {
      this.choiceRink = true;
    } else {
      this.choiceRink = false;
    }
  }

  getPlace(id: string){
    this.posts.get(id).pipe(take(1)).toPromise().then((post: Post) => {
      // console.log(post);
      if (post) {
        this.placeTitle = post.title;
        this.placeAdress = post.address
      }
      });
  }

  onCategoryCheck(category: User, event: Event|any) {
    if (event.target.checked) {
      this.places.push(category.id);
    } else {
      const index = this.places.indexOf(category.id);
      if (index !== -1) {
        this.places.splice(index, 1);
      }
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAvatarChange(event: Event) {
    this.avatar = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.avatarSrc = reader.result;
    };
    reader.readAsDataURL(this.avatar);
  }

  async sendBankAccountLink() {
    this.loading = true
    const result = await this.users.getBankAccountLink(this.stripeAccount.id, this.id).then((result) => {
      console.log('RESULT OF ACCOUNT LINK', result)
      this.resultBankLink = result.data
    })
    this.loading = false
  }

  async getBankAccount() {
    const result = await this.users.getBankAccount(this.stripeAccount.id).then((result) => {
      console.log('USER INFO BANK ACCOUNT', result)
      this.resultBank = result.data
    })
  }

  async removeBankAccount() {
    this.loading = true
    const result = await this.users.removeBankAccount(this.stripeAccount.id, this.id).then((result) => {
      console.log('REMOVE BANK ACCOUNT', result)
      if(result.data.deleted) {
        this.stripeAccount = null
        this.resultBank = {charges_enabled:false, requirements: {disabled_reason:''}}
        this.alert.success(this.i18n.get('UserBankAccountRemoved'), false, 5000, true);
      }
    })
    this.loading = false
  }

  async createBankAccount() {
    this.loading = true
    const newAccount = {email:this.userData.email,uid:this.id}
    const result = await this.users.createBankAccount(newAccount).then((result) => {
      console.log('CREATE USER BANK ACCOUNT', result)
      this.stripeAccount = result.data
      this.resultBank = result.data
    })
    this.loading = false
  }

  updateUser(event: Event, form: HTMLFormElement) {
    form.isSubmitted = true;
    if (form.checkValidity()) {
      const target = event.target as any;
      const startLoading = () => {
        target.isDisabled = true;
        target.isLoading = true;
      };
      const stopLoading = () => {
        target.isDisabled = false;
        target.isLoading = false;
      };

      if(this.places.length == 0) {
        return alert('Vous devez attribuer au moins 1 établissement à l\'utilisateur pour continuer...')
      }


      startLoading();
      // Edit user
      const data: User = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        rink: this.rink,
        stripe: this.stripeAccount,
        places: this.places,
        password: this.password,
        birthDate: this.birthDate ? new Date(this.birthDate).getTime() : null,
        role: this.role,
        bio: this.bio
      };
      if (this.avatar) {
        data.avatar = this.avatar;
      }
      this.users.edit(this.id, data, {
        email: this.userData.email,
        password: this.userData.password
      }).then(() => {
        this.userData = {...this.userData, ...data}; // override old user data
        this.alert.success(this.i18n.get('UserUpdated'), false, 5000, true);
        this.navigation.redirectTo('users', 'list');
      }).catch((error: Error) => {
        this.alert.error(error.message);
      }).finally(() => {
        stopLoading();
      });
    }
  }

}
