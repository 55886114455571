// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCJYs1EgU7ERfQ37QfK_fbuYJap-_gFRmY",
    authDomain: "vtsolutionclient.firebaseapp.com",
    databaseURL: "https://vtsolutionclient-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "vtsolutionclient",
    storageBucket: "vtsolutionclient.appspot.com",
    //messagingSenderId: "737771341870",
    appId: "1:737771341870:web:dbf5f86840cf3becebf7bf",
    measurementId: "G-6D55F8YQZX"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
