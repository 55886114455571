import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription, Observable } from 'rxjs';
import { Session } from '../../../models/collections/sessions.model';
import { SessionsService } from '../../../services/collections/sessions.service';
import { map, takeUntil } from 'rxjs/operators';
import { refreshDataTable } from '../../../helpers/datatables.helper';
import { AlertService } from '../../../services/alert.service';
import { NavigationService } from '../../../services/navigation.service';
import { I18nService } from '../../../services/i18n.service';
import { Post } from '../../../models/collections/post.model';
import { PostsService } from '../../../services/collections/posts.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../../../services/settings.service';
import { Language } from '../../../models/language.model';
import { CurrentUserService } from '../../../services/current-user.service';

@Component({
  selector: 'fa-posts-list',
  templateUrl: './sessions-list.component.html',
  styleUrls: ['./sessions-list.component.css']
})
export class SessionsListComponent implements OnInit, OnDestroy {

  private id: string;
  total:any
  filterPlace:any
  currentPlaceNameFilter:any
  postsByStatusLanguage: string;
  private postsByStatusLanguageChange: Subject<void> = new Subject<void>();
  isPlaceFilter:boolean = false
  allPosts: Observable<Session[]>;
  selectedPost: Session = null;
  @ViewChild(DataTableDirective, {static : false}) private dataTableElement: DataTableDirective;
  dataTableOptions: DataTables.Settings|any = {
    responsive: true,
    aaSorting: [],
    language:{
      processing:     "Traitement en cours...",
      search:         "Rechercher&nbsp;:",
      lengthMenu:    "Afficher _MENU_ &eacute;l&eacute;ments",
      info:           "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
      infoEmpty:      "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
      infoFiltered:   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
      infoPostFix:    "",
      loadingRecords: "Chargement en cours...",
      zeroRecords:    "Aucun &eacute;l&eacute;ment &agrave; afficher",
      emptyTable:     "Aucun résultat",
      paginate: {
          first:      "Premier",
          previous:   "Pr&eacute;c&eacute;dent",
          next:       "Suivant",
          last:       "Dernier"
      }},
      aria: {
        sortAscending:  ": activer pour trier la colonne par ordre croissant",
        sortDescending: ": activer pour trier la colonne par ordre décroissant"
    }
  };
  dataTableTrigger: Subject<void> = new Subject();
  private subscription: Subscription = new Subscription();
  allStatus: { labels: object, colors: object };
  allCategories: Post[] = [];
  allLanguages: Language[] = [];
  private routeParamsChange: Subject<void> = new Subject<void>();
  isLoading: boolean = true;
  isCategoriesLoading: boolean = true;
  dateNow:any;
  stateOrder:string = 'succeeded'
  statistics:any;
  constructor(
    private sessions: SessionsService,
    private categories: PostsService,
    private alert: AlertService,
    private i18n: I18nService,
    private route: ActivatedRoute,
    public navigation: NavigationService,
    public currentUser: CurrentUserService,
    private settings: SettingsService
  ) { }

  ngOnInit() {

    this.dateNow = Math.floor(Date.now()/1000);
    // Get all status
    this.allStatus = this.sessions.getAllStatusWithColors();
    // Get all categories
    this.subscription.add(
      this.categories.getAll().pipe(map((categories: Post[]) => {
        const allCategories: Post[] = [];
        categories.forEach((category: Post) => {
          //console.log(category.id)
          allCategories[category.id] = category['title'];
        });

        
    this.route.params.forEach((params) => {       
      if (params.id) {
      this.isPlaceFilter = true
      this.filterPlace = params.id
      } else {
        this.isPlaceFilter = false
      }
    })

        return allCategories;
      })).subscribe((categories: Post[]) => {
        // console.log(categories);
        this.allCategories = categories;
        this.isCategoriesLoading = false;
      })
    );
    // Get all languages
    this.settings.supportedLanguages.forEach((language: Language) => {
      this.allLanguages[language.key] = language;
    });


    // Get route params
    this.subscription.add(
      this.route.params.subscribe((params: { id: string, status: string, categoryId: string, authorId: string }) => {

        this.routeParamsChange.next();
        this.isLoading = true;
        // Get all posts
        this.allPosts = this.sessions.getWhereFn(ref => {
          let query: any = ref;

          query = query.where('placesMulti', 'array-contains-any', this.currentUser.data.places);

         if(params.id) {
            query = query.where('place', '==', params.id);
          }
          // Filter by status
         // if (this.postsByStatusLanguage == "succeeded") {
            query = query.where('status', '==', this.stateOrder);
          //}
          // Filter by category
           if (params.categoryId) {
            query = query.where('categories', 'array-contains', params.categoryId);
          }
          // Filter by author
          else if (params.authorId) {
            query = query.where('createdBy', '==', params.authorId);
          }
          //query = query.orderBy('createdAt', 'desc'); // requires an index to work
          return query;
        }, true).pipe(
          map((posts: Session[]) => {
            return posts.sort((a: Session, b: Session) => b.created - a.created);
          }),
          takeUntil(this.routeParamsChange)
        );
        this.subscription.add(
          this.allPosts.subscribe((posts: Session[]) => {
            let countPers = []
            posts.forEach(element => {
              countPers.push(element)
            });
            // console.log(posts);
            // Refresh datatable on data change
            refreshDataTable(this.dataTableElement, this.dataTableTrigger);
            this.statistics = countPers.length
            this.isLoading = false;    
          })
        );
      })
    );
  }

  onPostsLanguageChange() {
    
    this.subscription.add(
    this.route.params.subscribe((params: { id: string, status: string, categoryId: string, authorId: string }) => {

      this.routeParamsChange.next();
      this.isLoading = true;
      // Get all posts
      this.allPosts = this.sessions.getWhereFn(ref => {
        let query: any = ref;

       if(params.id) {
          query = query.where('place', '==', params.id);
        }
        // Filter by status
       // if (this.postsByStatusLanguage == "succeeded") {
          query = query.where('status', '==', this.stateOrder);
        //}
        // Filter by category
         if (params.categoryId) {
          query = query.where('categories', 'array-contains', params.categoryId);
        }
        // Filter by author
        else if (params.authorId) {
          query = query.where('createdBy', '==', params.authorId);
        }
        //query = query.orderBy('createdAt', 'desc'); // requires an index to work
        return query;
      }, true).pipe(
        map((posts: Session[]) => {
          return posts.sort((a: Session, b: Session) => b.created - a.created);
        }),
        takeUntil(this.routeParamsChange)
      );
      //this.subscription.add(
        this.allPosts.subscribe((posts: Session[]) => {
          let countPers = []
          posts.forEach(element => {
            countPers.push(element)
          });
          // console.log(posts);
          // Refresh datatable on data change
          refreshDataTable(this.dataTableElement, this.dataTableTrigger, true);
          this.statistics = countPers.length
          this.isLoading = false;      
        })
     //);
    })
    )

  }



  ngOnDestroy() {
    this.dataTableTrigger.unsubscribe();
    this.subscription.unsubscribe();
    this.routeParamsChange.next();
    this.isPlaceFilter = false
  }

  onPostsByStatusLanguageChange() {
    this.routeParamsChange.next();
    //this.getPostsByStatus();
  }

  /*
  private setPostStatus(event: Event, post: Session, status: SessionStatus) {
    const target = event.target as any;
    target.disabled = true;
    this.sessions.setStatus(post.id, status).catch((error: Error) => {
      this.alert.error(error.message);
      target.disabled = false;
    });
  }

  publishPost(event: Event, post: Session) {
    this.setPostStatus(event, post, PostStatus.Published);
  }

  moveToTrash(event: Event, post: Session) {
    this.setPostStatus(event, post, PostStatus.Trash);
  }

  deletePost(post: Session) {
    this.sessions.delete(post.id, {
      imagePath: (post.image as any).path as string,
      lang: post.lang,
      translationId: post.translationId,
      translations: post.translations
    }).then(() => {
      this.alert.success(this.i18n.get('PostDeleted', { title: post.title }), false, 5000);
    }).catch((error: Error) => {
      this.alert.error(error.message);
    });
  }
*/
}
