import { Component, OnInit, OnDestroy } from '@angular/core';
import { initTextEditor } from '../../../helpers/posts.helper';
import { I18nService } from '../../../services/i18n.service';
import { slugify } from '../../../helpers/functions.helper';
import { CategoriesService } from '../../../services/collections/categories.service';
import { Category } from '../../../models/collections/category.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { AlertService } from '../../../services/alert.service';
import { PostsService } from '../../../services/collections/posts.service';
import { NavigationService } from '../../../services/navigation.service';
import { Post, PostStatus } from '../../../models/collections/post.model';
import { getEmptyImage } from '../../../helpers/assets.helper';
import { ActivatedRoute } from '@angular/router';
import { Tariff } from '../../../models/tarifs.model';
import { SessionsService } from '../../../services/collections/sessions.service';
import { initPieChart, initBarChartTarif, initBarChart } from '../../../helpers/charts.helper';
import { FilterPipe } from 'w-ng5';
import { DatePipe } from '@angular/common';
import { UsersService } from '../../../services/collections/users.service';
@Component({
  selector: 'fa-posts-edit',
  templateUrl: './posts-stats.component.html',
  styleUrls: ['./posts-stats.component.css']
})
export class PostsStatsComponent implements OnInit, OnDestroy {
  loading:boolean = true
  private id: string;
  title: string;
  editor: any;
  status: PostStatus;
  language: string;
  slug: string;
  email:string;
  phone:string;
  date: string;
  private image: File;
  imageSrc: string|ArrayBuffer;
  private logo: File;
  logoSrc: string|ArrayBuffer;
  horaires:{};
  address: {};
  mondayStart:{};
  mondayEnd:{};
  tuesdayStart:{};
  tuesdayEnd:{};
  wednesdayStart:{};
  wednesdayEnd:{};
  thursdayStart:{};
  thursdayEnd:{};
  fridayStart:{};
  fridayEnd:{};
  saturdayStart:{};
  saturdayEnd:{};
  sundayStart:{};
  sundayEnd:{};

  lat:string;
  lng:string;
  street:string;
  streetNumber:string;
  locality:string;
  postcode:string;
  state:string;
  country:string;
  tarifBeforeAdd:any = []
  tarifs:any = []
  orders:any
  dateReport = Math.floor(Date.now()/1000)
  isOrders:boolean = false
  resumeSubscribe:any
  machintruc:any = []
  truchcouette:any = []
  isToday:boolean = true
  resumeOrders:any = []
  newDataOrder:any = []
  public options: any[] = [{
    name: '',
    price: '',
  }];
  orderCount:number = 0
  orderCountTotal:number = 0
  checkedCategories: string[] = [];
  categoriesObservable: Observable<Category[]>;
  newCategory: string;
  isSubmitButtonsDisabled: boolean = false;
  allStatus: object|any = {};
  private subscription: Subscription = new Subscription();
  private routeParamsChange: Subject<void> = new Subject<void>();
  bestPlace:string;


  constructor(
    private i18n: I18nService,
    private categories: CategoriesService,
    private alert: AlertService,
    private posts: PostsService,
    public navigation: NavigationService,
    private route: ActivatedRoute,
    private sessions:SessionsService,
    private pipe: FilterPipe,
    private users: UsersService,
  ) { }

  ngOnDestroy() {
    this.resumeSubscribe.unsubscribe()
  }


  ngOnInit() {
    this.allStatus = this.posts.getAllStatus();
    this.isSubmitButtonsDisabled = true;
    this.subscription.add(
      this.route.params.subscribe((params: { id: string }) => {
        // console.log(params);
        this.posts.get(params.id).pipe(take(1)).toPromise().then((post: Post) => {
          // console.log(post);
          if (post) {
            this.id = post.id;
            this.title = post.title;
            this.status = post.status;
            this.slug = post.slug;
            this.date = new Date(post.date).toISOString().slice(0, 10);
            this.language = post.lang;
            this.email = post.email;
            this.phone = post.phone;
            this.horaires = post.horaires;
            this.tarifBeforeAdd = post.tarifs;
            this.tarifs = post.tarifs;
            this.options = post.options;
            this.mondayStart = post.horaires['monday'].start;
            this.mondayEnd = post.horaires['monday'].end;
            this.tuesdayStart = post.horaires['tuesday'].start;
            this.tuesdayEnd = post.horaires['tuesday'].end;  
            this.wednesdayStart = post.horaires['wednesday'].start,
            this.wednesdayEnd = post.horaires['wednesday'].end,
            this.thursdayStart = post.horaires['thursday'].start,
            this.thursdayEnd = post.horaires['thursday'].end,
            this.fridayStart = post.horaires['friday'].start,
            this.fridayEnd = post.horaires['friday'].end,
            this.saturdayStart = post.horaires['saturday'].start,
            this.saturdayEnd = post.horaires['saturday'].end,
            this.sundayStart = post.horaires['sunday'].start,
            this.sundayEnd = post.horaires['sunday'].end,

            this.getLatestsStats(this.id)

            this.logo = null;
            this.image = null;
            this.imageSrc = getEmptyImage();
            this.logoSrc = getEmptyImage();
            if (post.image) {
              this.posts.getImageUrl(post.image as  string).pipe(take(1)).toPromise().then((imageUrl: string) => {
                this.imageSrc = imageUrl;
                console.log('image', this.imageSrc)
              });
            }
            if (post.logo) {
              this.posts.getImageUrl(post.logo as  string).pipe(take(1)).toPromise().then((logoUrl: string) => {
                this.logoSrc = logoUrl;
              });
            }
            this.isSubmitButtonsDisabled = false;
            
          } else {
            this.navigation.redirectTo('posts', 'list');
          }
        });
      })
    );
    
  }


    reloadDataWithFilterDate(place) {
      this.newDataOrder = []
      console.log('le resumer order', this.resumeOrders)
      const datePipe = new DatePipe('en-US');
      this.resumeOrders.forEach(element => {
        console.log(datePipe.transform(element['created'], 'yyyy-MM-dd'))
        if(datePipe.transform(element['created'], 'yyyy-MM-dd') == datePipe.transform(this.dateReport*1000, 'yyyy-MM-dd')) {
          this.newDataOrder.push(element)
        }
      });

      if(this.newDataOrder.length > 0) {
        this.isOrders = true
        this.orderCount = this.newDataOrder.length
        this.orderCountTotal = 0
        this.machintruc = []

        this.newDataOrder.forEach(element => {
          this.orderCountTotal = this.orderCountTotal + element.total
          element['cart'].forEach(element2 => {
            this.machintruc.push(element2)
          });
         });
  
   
      const truc = this.machintruc.reduce(function (r, a) {
           r[a.name] = r[a.name] || [];
           r[a.name].push(a);
           return r;
       }, Object.create(null));
       console.log('VESTY', truc)
      this.truchcouette = truc
  
         $('#sessions-by-status').remove();
         $('#graph-sessions-by-status').append('<canvas height="260px" id="sessions-by-status" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
  
         const data = Object.keys(truc).map((key: string) => truc[key].length);
         const labels = Object.keys(truc).map((key: string) => truc[key][0].name); //this.i18n.get(postsByStatus[key].label)
         setTimeout(() => { // setTimeout used to wait for canvas html element to render
          initBarChart('#sessions-by-status', data, labels, 1000);
         }, 0);
  
  
         //get stats by date //3 last days //filter by date asc
         const machintruc2 = this.newDataOrder.sort(function(x, y){
          return x.created - y.created;
        })  
  
         const datePipe = new DatePipe('en-US');
          const truc2 = machintruc2.reduce(function (r, a) {
           r[datePipe.transform(a.created, 'dd-MM-yyyy')] = r[datePipe.transform(a.created, 'dd-MM-yyyy')] || [];
           r[datePipe.transform(a.created, 'dd-MM-yyyy')].push(a);
           return r;
       }, Object.create(null));
         console.log('VESTY DATE', truc2)
  
    
         $('#sessions-by-date').remove();
         $('#graph-sessions-by-date').append('<canvas height="260px" id="sessions-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
  
         const data2 = Object.keys(truc2).slice(-3).map((key: string) => truc2[key].length);
         const labels2 = Object.keys(truc2).slice(-3).map((key: string) => key); //this.i18n.get(postsByStatus[key].label)
         setTimeout(() => { // setTimeout used to wait for canvas html element to render
          initBarChartTarif('#sessions-by-date', data2, labels2, 1000);
         }, 0);



       } else {
        this.isOrders = false
        this.orderCount = 0
        this.orderCountTotal = 0
        this.machintruc = []
       }
   
    }


    getStatsByDay() {
      this.resumeSubscribe.unsubscribe()
      const date = document.querySelector('input');
      const datePipe = new DatePipe('en-US');
      if(date) {
        const newDate = new Date(date.value).getTime();
        console.log('pfff', newDate)
        this.dateReport = Math.floor(newDate / 1000)
        this.isToday = false
        this.reloadDataWithFilterDate(this.id)
      } else {
        alert('oups, veuillez choisisr une date...')
      }
    }


    getStatsToday() {
      this.resumeSubscribe.unsubscribe()
      this.dateReport = Math.floor(Date.now()/1000)
      this.isToday = false
      this.reloadDataWithFilterDate(this.id)
    }


    getStatsAll() {
      this.dateReport = Math.floor(Date.now()/1000)
      this.isToday = true
      this.getLatestsStats(this.id)
    }



  async getLatestsStats(place) {
    this.resumeSubscribe = this.sessions.getStatsResumeByPlace(ref => {
        let query: any = ref;
        query = query.where('place', '==', place);
        return query;
      }
    ).subscribe((result) => {
      this.isOrders = true
      this.resumeOrders = result
      console.log('ohhhh result', result)
      this.orderCount = 0
      this.orderCountTotal = 0
      this.machintruc = []

      if(result.length > 0) {
        this.isOrders = true
        this.loading = false
        this.orderCount = result.length
       } else {
        this.loading = false
        this.isOrders = false
        this.orderCount = 0
       }

       
       result.forEach(element => {
        this.orderCountTotal = this.orderCountTotal + element.total
        element['cart'].forEach(element2 => {
          this.machintruc.push(element2)
        });
       });

 
    const truc = this.machintruc.reduce(function (r, a) {
         r[a.name] = r[a.name] || [];
         r[a.name].push(a);
         return r;
     }, Object.create(null));
     console.log('VESTY', truc)
    this.truchcouette = truc

       $('#sessions-by-status').remove();
       $('#graph-sessions-by-status').append('<canvas height="260px" id="sessions-by-status" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

       const data = Object.keys(truc).map((key: string) => truc[key].length);
       const labels = Object.keys(truc).map((key: string) => truc[key][0].name); //this.i18n.get(postsByStatus[key].label)
       setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChart('#sessions-by-status', data, labels, 1000);
       }, 0);


       //get stats by date //3 last days //filter by date asc
       const machintruc2 = result.sort(function(x, y){
        return x.created - y.created;
      })  

       const datePipe = new DatePipe('en-US');
        const truc2 = machintruc2.reduce(function (r, a) {
         r[datePipe.transform(a.created, 'dd-MM-yyyy')] = r[datePipe.transform(a.created, 'dd-MM-yyyy')] || [];
         r[datePipe.transform(a.created, 'dd-MM-yyyy')].push(a);
         return r;
     }, Object.create(null));
       console.log('VESTY DATE', truc2)

  
       $('#sessions-by-date').remove();
       $('#graph-sessions-by-date').append('<canvas height="260px" id="sessions-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

       const data2 = Object.keys(truc2).slice(-3).map((key: string) => truc2[key].length);
       const labels2 = Object.keys(truc2).slice(-3).map((key: string) => key); //this.i18n.get(postsByStatus[key].label)
       setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChartTarif('#sessions-by-date', data2, labels2, 1000);
       }, 0);

     

      })



    

    }



    

countTotalPriceTarif(list:any) {
  var total = 0
  list.forEach(element => {
    if(element['price'] > 0) {
      total = total + element.priceTotal
    } else {
      total = total + element.price
    }
  });
  return total
}

        




}
