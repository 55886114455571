import { Component, OnInit, OnDestroy } from '@angular/core';
import { PostsService } from '../../services/collections/posts.service';
import { SessionsService } from '../../services/collections/sessions.service';
import { PagesService } from '../../services/collections/pages.service';
import { CustomersService } from '../../services/collections/customers.service';
import { UsersService } from '../../services/collections/users.service';
import { TranslationsService } from '../../services/collections/translations.service';
import { Observable, Subscription, Subject } from 'rxjs';
import { Post, PostStatus } from '../../models/collections/post.model';
import { Session, SessionStatus } from '../../models/collections/sessions.model'
import { Language } from '../../models/language.model';
import { Category } from '../../models/collections/category.model';
import { SettingsService } from '../../services/settings.service';
import { CategoriesService } from '../../services/collections/categories.service';
import { map, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { initPieChart, initBarChartTarif, initBarChart } from '../../helpers/charts.helper';
import { I18nService } from '../../services/i18n.service';
import { CurrentUserService } from '../../services/current-user.service';
import { FilterPipe } from 'w-ng5';
import { firestore } from 'firebase';


type PostByStatus = {
  label: string,
  count: number,
  //percentage: number
};

type SessionByStatus = {
  label: string,
  count: number,
  //percentage: number
};

import { DatePipe } from '@angular/common';

@Component({
  selector: 'fa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {

  statistics: { posts?: number, sessions?: number, sessionPending?:number, customers?: number, pages?: number, comments?: number, users?: number, translations?: number } = {};
  latestPosts: Observable<Post[]>;
  latestSessions: Observable<Session[]>;
  postsLanguage: string;
  sessionsStatus: string;
  postsByStatus: Observable<PostByStatus[]>;
  postsByStatusLanguage: string;
  sessionsByStatus: Observable<SessionByStatus[]>;
  languages: Language[];
  allPostsStatus: { labels: object, colors: object };
  allSessionsStatus: { labels: object, colors: object };
  allPostsCategories: Category[] = [];
  private subscription: Subscription = new Subscription();
  private postsLanguageChange: Subject<void> = new Subject<void>();
  private SessionStatusChange: Subject<void> = new Subject<void>();
  private postsByStatusLanguageChange: Subject<void> = new Subject<void>();
  private listPlacesName:Post[] = [];
  private totalOrder:number = 0
  isOrders:boolean = false
  resumeSubscribe:any
  userPlaces:any = this.currentUser.get()['places']
  rewardDaysStats = 7
  rewardPaymentsStats = 14
  rewardPlacesStats = 14
  bestPlace:string;
  bestDate:string;
  bestPaymentType:string;

  constructor(
    private posts: PostsService,
    private sessions: SessionsService,
    private pages: PagesService,
    private customers: CustomersService,
    private users: UsersService,
    private translations: TranslationsService,
    private categories: CategoriesService,
    private settings: SettingsService,
    public navigation: NavigationService,
    public currentUser: CurrentUserService,
    private i18n: I18nService,
    private pipe: FilterPipe,
  ) { }

  ngOnInit() {
    // Get statistics
    this.getStatistics();
    this.getLatestsStats();
    // Get languages
    this.languages = this.settings.getActiveSupportedLanguages();
    this.postsLanguage = '*';//this.languages[0].key;
    this.sessionsStatus = 'succeeded';//this.languages[0].key;
    this.postsByStatusLanguage = '*';//this.languages[0].key;
    // Get all posts status
    this.allPostsStatus = this.posts.getAllStatusWithColors();
    this.allSessionsStatus = this.sessions.getAllStatusWithColors();
    // Get all posts categories
    this.subscription.add(
      this.categories.getAll().pipe(map((categories: Category[]) => {
        const allCategories: Category[] = [];
        categories.forEach((category: Category) => {
          allCategories[category.id] = category;
        });
        console.log('categories', allCategories);
        return allCategories;
      })).subscribe((categories: Category[]) => {
        this.allPostsCategories = categories;
      })
    );
    // Get latest posts
    this.getLatestPosts();
    // Get latest orders
    //this.getOrdersBystatus()
    // Get posts by status
    this.getPostsByStatus();
    this.getLatestsOrders();

    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.postsLanguageChange.next();
    this.SessionStatusChange.next()
    this.postsByStatusLanguageChange.next();
    this.resumeSubscribe.unsubscribe();
  }

  private async getStatistics() {
    this.statistics.posts = this.userPlaces.length //await this.posts.countAll();
    //this.statistics.sessions = await this.sessions.countAll();
    this.statistics.sessionPending = await this.sessions.countWhereFn(ref => ref.where('status', '==', 'succeeded'));
    this.statistics.pages = await this.pages.countAll();
    this.statistics.customers = await this.customers.countAll();
    this.statistics.comments = 0; // ToDo
    if (this.currentUser.isAdmin()) {
      this.statistics.users = await this.users.countAll();
    }
    this.statistics.translations = await this.translations.countAll();
  }

  private async getLatestPosts() {
    const resultUser = await this.currentUser.get()
    this.latestPosts = this.posts.getWhereFn(ref => {
      let query: any = ref;
      // Filter by lang
      query = query.where(firestore.FieldPath.documentId(), 'in', resultUser.places);
      if (this.postsLanguage !== '*') {
        query = query.where('categories', 'array-contains', this.postsLanguage);
      }
      // orderBy & limit requires a database index to work with the where condition above
      // as a workaround, they were replaced with client side sort/slice functions below
      // query = query.orderBy('createdAt', 'desc');
      // query = query.limit(5);
      return query;
    }, true).pipe(
      map((posts: Post[]) => {
        // console.log(posts);
        const allCategories2: Post[] = [];
        posts.forEach((post: Post) => {
          //console.log(category.id)
          if (this.postsLanguage == '*') {
          allCategories2[post.id] = post['title'];
          }
        });
        if (this.postsLanguage == '*') {
        this.listPlacesName = allCategories2
        }
        return posts.sort((a: Post, b: Post) => b.createdAt - a.createdAt).slice(0, 15);
      }),
      takeUntil(this.postsLanguageChange)
    );
  }




  private async getLatestsOrders() {
    const resultUser = await this.currentUser.get()
    this.latestSessions = this.sessions.getWhereFn(ref => {
    let query: any = ref;
      // Filter by lang
      if (this.sessionsStatus !== '*') {
        query = query.where('status', '==', this.sessionsStatus);
      } else {
      query = query.where('status', '==', 'succeeded');
      }
      //Only display user place
      //query = query.where('place', 'in', resultUser.places);
      query = query.where('placesMulti', 'array-contains-any', resultUser.places);
      // orderBy & limit requires a database index to work with the where condition above
      // as a workaround, they were replaced with client side sort/slice functions below
       query = query.orderBy('created', 'asc');
      // query = query.limit(5);
      return query;
    }, true).pipe(
      map((sessions: Session[]) => {
         console.log(sessions);
        return sessions.sort((a: Session, b: Session) => b.created - a.created).slice(0, 100);
      }),
      takeUntil(this.SessionStatusChange)
    );
    }

  

 
  async getLatestsStats() {
    this.isOrders = true

    const resultUser = await this.currentUser.get()
      const userPlaces = resultUser.places

      this.resumeSubscribe = this.sessions.getStatsResume(userPlaces).subscribe((resultt) => {
        const result = resultt.sort((a: Session, b: Session) => b.created + a.created);
        console.log('ohhhh result', result)
        if(result.length > 0) {
          this.isOrders = true
          this.statistics.sessions = result.length

          this.totalOrder = 0
          result.forEach((session: Session) => {
          this.totalOrder =  this.totalOrder + session.total
        })

         } else {
          this.isOrders = false
         }
        //const machintruc = result   

         //get stats by date //3 last days //filter by date asc
       const machintruc = result.sort(function(x, y){
        return x.created - y.created;
      })  
      
        const truc = machintruc.reduce(function (r, a) {
         r[a.place] = r[a.place] || [];
         r[a.place].push(a);
         return r;
     }, Object.create(null));
     console.log('VESTY', truc)

       $('#sessions-by-status').remove();
       $('#graph-sessions-by-status').append('<canvas height="260px" id="sessions-by-status" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

       $('#sessions-by-date').remove();
       $('#graph-sessions-by-date').append('<canvas height="260px" id="sessions-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

       $('#sessions-by-paymentType').remove();
       $('#graph-sessions-by-paymentType').append('<canvas height="260px" id="sessions-by-paymentType" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
       
       const data = Object.keys(truc).map((key: string) => truc[key].length);
       const labels = Object.keys(truc).map((key: string) => truc[key][0]['cart'][0].placeName); //this.i18n.get(postsByStatus[key].label)
       setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChart('#sessions-by-status', data, labels, this.rewardPlacesStats);
       }, 0);

      const bestPlace = Math.max(...data);
      const index = data.indexOf(bestPlace);
      this.bestPlace = labels[index];
      console.log('MMMMMM', this.bestPlace)

       //get stats by date //3 last days //filter by date asc
       const machintruc2 = result.sort(function(x, y){
        return x.created - y.created;
      })  

       const datePipe = new DatePipe('en-US');
        const truc2 = machintruc2.reduce(function (r, a) {
         r[datePipe.transform(a.created, 'dd-MM-yyyy')] = r[datePipe.transform(a.created, 'dd-MM-yyyy')] || [];
         r[datePipe.transform(a.created, 'dd-MM-yyyy')].push(a);
         return r;
     }, Object.create(null));
       console.log('VESTY DATE', truc2)

  
       const data2 = Object.keys(truc2).slice(-this.rewardDaysStats).map((key: string) => truc2[key].length);
       const labels2 = Object.keys(truc2).slice(-this.rewardDaysStats).map((key: string) => key); //this.i18n.get(postsByStatus[key].label)
       setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChartTarif('#sessions-by-date', data2, labels2, this.rewardDaysStats);
       }, 0);


       const bestDate = Math.max(...data2);
       const index2 = data2.indexOf(bestDate);
       this.bestDate = labels2[index2];
       console.log('MMMMMM', this.bestDate)




       const paymentTypes = machintruc.reduce(function (r, a) {
        r[a.paymentType] = r[a.paymentType] || [];
        r[a.paymentType].push(a);
        return r;
        }, Object.create(null));
        console.log('VESTY', truc)

        const datapaymentType = Object.keys(paymentTypes).map((key: string) => paymentTypes[key].length);
        const labelspaymentType = Object.keys(paymentTypes).map((key: string) => this.i18n.get(paymentTypes[key][0].paymentType)); //this.i18n.get(postsByStatus[key].label)
        setTimeout(() => { // setTimeout used to wait for canvas html element to render
          initBarChart('#sessions-by-paymentType', datapaymentType, labelspaymentType, this.rewardPaymentsStats);
        }, 0);

        const bestPaymentType = Math.max(...datapaymentType);
        const index3 = datapaymentType.indexOf(bestPaymentType);
        this.bestPaymentType = labelspaymentType[index3];
        console.log('MMMMMM', this.bestPaymentType)
      


      })


  }






  async changeReward() {
    this.resumeSubscribe.unsubscribe();
    const resultUser = await this.currentUser.get();
    const userPlaces = resultUser.places

    this.resumeSubscribe = this.sessions.getStatsResume(userPlaces).subscribe((resultt) => {
      const result =  resultt.sort((a: Session, b: Session) => b.created + a.created);
      console.log('ohhhh result', result)
      if(result.length > 0) {
        this.isOrders = true
        this.statistics.sessions = result.length

        this.totalOrder = 0
        result.forEach((session: Session) => {
        this.totalOrder =  this.totalOrder + session.total
      })

       } else {
        this.isOrders = false
       }
      //const machintruc = result   
        //get stats by date //3 last days //filter by date asc
     const machintruc = result.sort(function(x, y){
      return x.created - y.created;
    })  
      const truc = machintruc.reduce(function (r, a) {
       r[a.place] = r[a.place] || [];
       r[a.place].push(a);
       return r;
   }, Object.create(null));
   console.log('VESTY', truc)

     $('#sessions-by-status').remove();
     $('#graph-sessions-by-status').append('<canvas height="260px" id="sessions-by-status" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

     $('#sessions-by-date').remove();
     $('#graph-sessions-by-date').append('<canvas height="260px" id="sessions-by-date" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

     $('#sessions-by-paymentType').remove();
     $('#graph-sessions-by-paymentType').append('<canvas height="260px" id="sessions-by-paymentType" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');
     
     const data = Object.keys(truc)./*slice(-this.rewardPlacesStats).*/map((key: string) => truc[key].length);
     const labels = Object.keys(truc)./*slice(-this.rewardPlacesStats).*/map((key: string) => truc[key][0]['cart'][0].placeName); //this.i18n.get(postsByStatus[key].label)
     setTimeout(() => { // setTimeout used to wait for canvas html element to render
      initBarChart('#sessions-by-status', data, labels, this.rewardPlacesStats);
     }, 0);

     const bestPlace = Math.max(...data);
     const index = data.indexOf(bestPlace);
     this.bestPlace = labels[index];
     console.log('MMMMMM', this.bestPlace)



     //get stats by date //3 last days //filter by date asc
     const machintruc2 = result.sort(function(x, y){
      return x.created - y.created;
    })  

     const datePipe = new DatePipe('en-US');
      const truc2 = machintruc2.reduce(function (r, a) {
       r[datePipe.transform(a.created, 'dd-MM-yyyy')] = r[datePipe.transform(a.created, 'dd-MM-yyyy')] || [];
       r[datePipe.transform(a.created, 'dd-MM-yyyy')].push(a);
       return r;
   }, Object.create(null));
     console.log('VESTY DATE', truc2)


     const data2 = Object.keys(truc2).slice(-this.rewardDaysStats).map((key: string) => truc2[key].length);
     const labels2 = Object.keys(truc2).slice(-this.rewardDaysStats).map((key: string) => key); //this.i18n.get(postsByStatus[key].label)
     setTimeout(() => { // setTimeout used to wait for canvas html element to render
      initBarChartTarif('#sessions-by-date', data2, labels2, this.rewardDaysStats);
     }, 0);



     const bestDate = Math.max(...data2);
     const index2 = data2.indexOf(bestDate);
     this.bestDate = labels2[index2];
     console.log('MMMMMM', this.bestDate)



     const paymentTypes = machintruc.reduce(function (r, a) {
      r[a.paymentType] = r[a.paymentType] || [];
      r[a.paymentType].push(a);
      return r;
      }, Object.create(null));
      console.log('VESTY', truc)

      const datapaymentType = Object.keys(paymentTypes).map((key: string) => paymentTypes[key].length);
      const labelspaymentType = Object.keys(paymentTypes).map((key: string) => this.i18n.get(paymentTypes[key][0].paymentType)); //this.i18n.get(postsByStatus[key].label)
      setTimeout(() => { // setTimeout used to wait for canvas html element to render
        initBarChart('#sessions-by-paymentType', datapaymentType, labelspaymentType, this.rewardPaymentsStats);
      }, 0);


      const bestPaymentType = Math.max(...datapaymentType);
      const index3 = datapaymentType.indexOf(bestPaymentType);
      this.bestPaymentType = labelspaymentType[index3];
      console.log('MMMMMM', this.bestPaymentType)



    })


  }

  onPostsLanguageChange() {
    this.postsLanguageChange.next();
    this.getLatestPosts();
  }
  onSessionStatusChange() {
    this.SessionStatusChange.next();
    this.getLatestsOrders();
  }



  private getPostsByStatus() {
    this.postsByStatus = this.posts.getWhereFn(ref => {
      let query: any = ref;
      
      // Filter by lang
      if (this.postsByStatusLanguage !== '*') {
        query = query.where('lang', '==', this.postsByStatusLanguage);
      }
      return query;
    }, true).pipe(
      map((posts: Post[]) => {
        // console.log(posts);
        let postsByStatus: PostByStatus[] = [];
        Object.keys(PostStatus).forEach((key: string) => {
          postsByStatus[PostStatus[key]] = {
            label: key,
            count: 0,
            //percentage: 0
          };
        });
        // Get status count
        let total
        posts.forEach((post: Post) => {
          postsByStatus[post.status].count += 1;
        })
        // Convert count to %
        // const postsCount = posts.length;
        // Object.keys(postsByStatus).forEach((key: string) => {
        //   postsByStatus[key].percentage = Math.round((postsByStatus[key].count / postsCount) * 100);
        // });
        return postsByStatus;
      }),
      takeUntil(this.postsByStatusLanguageChange)
    );
    this.subscription.add(
      this.postsByStatus.subscribe((postsByStatus: PostByStatus[]) => {
        //console.log(postsByStatus);

        $('#posts-by-status').remove();
       $('#graph-posts-by-status').append('<canvas height="260px" id="posts-by-status" style="height: 260px; width: 100%; padding-top:0px; margin-top:0;"></canvas>');

        const data = Object.keys(postsByStatus).map((key: string) => postsByStatus[key].count);
        const labels = Object.keys(postsByStatus).map((key: string) => this.i18n.get(postsByStatus[key].label)); //this.i18n.get(postsByStatus[key].label)
        setTimeout(() => { // setTimeout used to wait for canvas html element to render
          initPieChart('#posts-by-status', data, labels, this.rewardPaymentsStats);
        }, 0);
      })
    );
  }



  

  async getOrdersBystatus() {
    this.totalOrder = 0
    const resultUser = await this.currentUser.get()
    const statusAvailable = ["succeeded", "terminated"]
    this.sessionsByStatus = this.sessions.getWhereFn(ref => {
      let query: any = ref;
    
      // Filter by status
      query = query.where('status', 'in', statusAvailable);
      // Filter by place's User
      //query = query.where('placesMutli', 'array-contains', resultUser['places']);

      return query;
    }, true).pipe(
      map((sessions: Session[]) => {
         console.log('les commandes', sessions);
        let sessionsByStatus: SessionByStatus[] = [];
        Object.keys(SessionStatus).forEach((key: string) => {
          sessionsByStatus[SessionStatus[key]] = {
            label: key,
            count: 0,
            //percentage: 0
          };
        });
        // Get status count
        this.totalOrder = 0
        sessions.forEach((session: Session) => {
          sessionsByStatus[session.status].count += 1;
          this.totalOrder =  this.totalOrder + session.total
        })
        // Convert count to %
        // const postsCount = posts.length;
        // Object.keys(postsByStatus).forEach((key: string) => {
        //   postsByStatus[key].percentage = Math.round((postsByStatus[key].count / postsCount) * 100);
        // });
        return sessionsByStatus;
      }),
      //takeUntil(this.postsByStatusLanguageChange)
    );
    this.subscription.add(
      this.sessionsByStatus.subscribe((sessionsByStatus: SessionByStatus[]) => {
        /*const data = Object.keys(sessionsByStatus).map((key: string) => sessionsByStatus[key].count);
        const labels = Object.keys(sessionsByStatus).map((key: string) => this.i18n.get(sessionsByStatus[key].label));
        setTimeout(() => { // setTimeout used to wait for canvas html element to render
          initPieChart('#sessions-by-status', data, labels);
        }, 0);*/
      })
    );
  }




  onPostsByStatusLanguageChange() {
    this.postsByStatusLanguageChange.next();
    this.getPostsByStatus();
    //this.getOrdersBystatus()
  }

}